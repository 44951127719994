import logo from "./logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";

const SampleWorks = ({ name = "", url = "" }) => {
	return (
		<div class="col-md-4 service-block">
			<div class="row">
				<div class="col-md-12">
					<div class="service-label text-center">
						<a href={url} target="_blank">{name}</a>
					</div>
				</div>
				<div class="col-md-12">
					<iframe
						title={name}
						src={url}
						style={{ width: "100%", height: "400px" }}
					/>
				</div>
			</div>
		</div>
	);
};
function App() {
	const [show, setShow] = useState(true);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	useEffect(() => {
		// setTimeout(()=>{
		//     handleClose();
		// }, 2000)
	});
	return <div>
			{/* <Modal show={show} onHide={handleClose} fullscreen={true}>
        <Modal.Body style={{ backgroundColor: "#f8f4f4", padding:0 }}>
          <video width="100%" autoPlay muted style={{marginTop:-100}} onEnded={()=>{
            setShow(false)
          }}>
            <source src="assets/Intro.mp4" type="video/mp4" />
          </video>
        </Modal.Body>
      </Modal> */}
			<div class="container-fluid fixed-top page-body" id="header">
				<nav class="navbar navbar-expand-lg navbar-light bg-light" id="navigation">
					<a class="navbar-brand" href="#">
						<img src="assets/alapaapp_logo_4@4x.png" alt="website logo" style={{ maxWidth: "300px", minWidth: "200px" }} />
					</a>
					<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-icon" />
					</button>
					<div class="collapse navbar-collapse" id="navbarNav">
						<ul class="navbar-nav ml-auto">
							<li class="nav-item active">
								<a class="nav-link" href="#">
									Home <span class="sr-only">(current)</span>
								</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="#services">
									Services
								</a>
							</li>
							{/* <li class="nav-item">
                <a class="nav-link" href="#contact">
                  Contact
                </a>
              </li> */}
						</ul>
					</div>
				</nav>
			</div>
			<div class="container-fluid page-body">
				<div class="row">
					<div class="col-md-6" style={{ paddingLeft: "60px", paddingRight: "60px", alignItems: "center", justifyContent: "center", display: "flex" }}>
						<h1 id="tagline">
							Software Design &amp; Development
							<div style={{ borderTop: "2px solid #5665e8", width: "50%", marginTop: 14 }}>
								{["Mobile Applications", "Web Applications", "Static Websites", "Desktop Applications", "UI/UX"].map(
									service => {
										return (
											<div
												style={{
													display: "flex",
													flexDirection: "row",
													marginTop: 20,
												}}
											>
												<FontAwesomeIcon
													icon={faCheckCircle}
													style={{ fontSize: "30px", color: "#5665e8" }}
												/>
												<span
													style={{
														fontSize: "20px",
														paddingLeft: "10px",
														verticalAlign: "middle",
													}}
												>
													{service}
												</span>
											</div>
										);
									}
								)}
								{["Internet of Things"].map(service => {
									return <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
											<FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: "30px", color: "#2a2a2a50" }} />
											<span style={{ fontSize: "20px", paddingLeft: "10px", verticalAlign: "middle", color: "#2a2a2a50" }}>
												{service}
											</span>
										</div>;
								})}
							</div>
						</h1>
					</div>

					<div class="col-md-6" style={{ paddingTop: "140px" }}>
						<video width="90%" autoPlay loop muted>
							<source src="assets/Alapaapp_main_4_service_fix.mp4" type="video/mp4" />
						</video>
					</div>
				</div>
				<div class="row" id="services" style={{ backgroundCOlor: "#FBFCFE" }}>
					<div class="col-md-12">
						<div class="module">
							<div class="module-header">
								<h1 style={{ fontSize: "46px", fontWeight: "bold" }}>
									Our Services
								</h1>
								<span>Custom software for your business.</span>
							</div>
						</div>
					</div>
					<div class="col-md-6 service-block">
						<div class="row">
							<div class="col-md-12">
								<img src="assets/Service1@2x.png" alt="web application vector" style={{ width: "100%", marginTop: "10px" }} />
							</div>
							<div class="col-md-12">
								<div class="service-label">Web Applications</div>
								<span class="service-description">
									Bridge the gap between you and your customers. Have a web
									application that will suit your business flow and run your
									business online.
								</span>
							</div>
						</div>
					</div>
					<div class="col-md-6 service-block">
						<div class="row">
							<div class="col-md-12">
								<img src="assets/Service2@2x.png" alt="desktop application vector" style={{ width: "100%", marginTop: "10px" }} />
							</div>
							<div class="col-md-12">
								<div class="service-label">Desktop Applications</div>
								<span class="service-description">
									We can build an efficient, user-friendly and intuitive
									desktop application for your internal business needs.
								</span>
							</div>
						</div>
					</div>
					<div class="col-md-6 service-block">
						<div class="row">
							<div class="col-md-12">
								<img src="assets/Service3@2x.png" alt="mobile application vector" style={{ width: "100%", marginTop: "10px" }} />
							</div>
							<div class="col-md-12">
								<div class="service-label">Mobile Applications</div>
								<span class="service-description">
									We can create practical and seamless experiences on any
									device and get the best of mobile technology for your
									business.
								</span>
							</div>
						</div>
					</div>
					<div class="col-md-6 service-block">
						<div class="row">
							<div class="col-md-12">
								<img src="assets/Service4@2x.png" alt="embedded system vector" style={{ width: "100%", marginTop: "0px" }} />
							</div>
							<div class="col-md-12">
								<div class="service-label">Internet of Things</div>
								<span class="service-description">
									Empower your business by collecting and transferring data
									over the internet through a system of interrelated and
									internet-connected devices.
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row" id="services" style={{ backgroundCOlor: "#FBFCFE" }}>
					<div class="col-md-12">
						<div class="module">
							<div class="module-header">
								<h1 style={{ fontSize: "46px", fontWeight: "bold" }}>
									Sample works
								</h1>
							</div>
						</div>
					</div>
					<SampleWorks name="TakoExpress" url="https://takoexpress.com/" />
					<SampleWorks name="Caylieh Dental" url="https://caylieh-dental.alapaapp.tech/" />
					<SampleWorks name="Futureway Platform" url="https://futureway.alapaapp.tech/" />
				</div>

				<div id="contact" style={{ display: "none" }}>
					<div class="col-md-12">
						<div class="ellipsis">
							<span>
								<i class="fa fa-circle" />
							</span>
							<span>
								<i class="fa fa-circle" />
							</span>
							<span>
								<i class="fa fa-circle" />
							</span>
						</div>
						<div class="module">
							<div class="module-header">
								<span>Contact Us</span>
								<br />
								<span>
									Want a software for your business? Contact us now!
									<br />
									<a href="mailto:sales@alapaapp.tech?subject=Inquiry" style={{ color: "#5665e8" }}>
										<i>sales@alapaapp.tech</i>
									</a>
									<span> &mdash;</span>
									<a href="sms:09458915937;" style={{ color: "#5665e8" }}>
										09458915937
									</a>
								</span>
							</div>
						</div>
					</div>
					<div class="col-md-12">
						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label for="email">Name</label>
									<input type="text" id="name" class="form-control" />
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="email">Email Address</label>
									<input type="email" id="email" class="form-control" />
								</div>
							</div>
						</div>
						<div class="form-group">
							<label for="message">Message</label>
							<textarea class="form-control" id="message" />
						</div>
					</div>
					<div class="col-md-2 offset-md-10">
						<button class="form-control" id="send-button">
							Send
						</button>
					</div>
				</div>
			</div>
			<div class="container-fluid page-body" id="black-bottom">
				<div class="row" id="contact">
					<div class="col-md-6" style={{ color: "white", padding: "10px", paddingLeft: "30px" }}>
						<span style={{ fontSize: "30px" }}>Get in Touch</span>
						<br />
						<table class="contact-table mt-3">
							<tbody>
								<tr>
									<td>
										<i class="fa fa-linkedin contact-icon" style={{ fontSize: "30px" }} />
									</td>
									<td>
										<a href="https://www.linkedin.com/company/alapa-app-tech" target="_blank" className="ml-2 text-white" style={{ fontSize: "20px" }}>
											LinkedIn
										</a>
									</td>
								</tr>
								<tr>
									<td>
										<i class="fa fa-facebook contact-icon" style={{ fontSize: "30px" }} />
									</td>
									<td>
										<a href="https://fb.com/alapaapptech" target="_blank" className="ml-2 text-white" style={{ fontSize: "20px" }}>
											Facebook
										</a>
									</td>
								</tr>
								<tr>
									<td>
										<i class="fa fa-twitter contact-icon" style={{ fontSize: "30px" }} />
									</td>
									<td>
										<a href="https://twitter.com/alapaapptech" target="_blank" className="ml-2 text-white" style={{ fontSize: "20px" }}>
											Twitter
										</a>
									</td>
								</tr>
								<tr>
									<td>
										<i class="fa fa-envelope-square contact-icon" style={{ fontSize: "30px" }} />
									</td>
									<td>
										<a href="mailto:sales@alapa-app.tech?subject=Inquiry" className="ml-2 text-white" style={{ fontSize: "20px" }}>
											sales@alapaapp.tech
										</a>
									</td>
								</tr>
								<tr>
									<td>
										<i class="fa fa-phone contact-icon" style={{ fontSize: "30px" }} />
									</td>
									<td>
										<a href="sms:09458915937" target="_blank" className="ml-2 text-white" style={{ fontSize: "20px" }}>
											09458915937
										</a>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div class="col-md-6 hide">
						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label for="email" style={{ color: "white" }}>
										Name
									</label>
									<input type="text" id="name" class="form-control" />
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="email" style={{ color: "white" }}>
										Email Address
									</label>
									<input type="email" id="email" class="form-control" />
								</div>
							</div>
						</div>
						<div class="form-group">
							<label for="message" style={{ color: "white" }}>
								Message
							</label>
							<textarea class="form-control" id="message" />
						</div>
						<div class="row">
							<div class="col-md-4">
								<button class="form-control" id="send-button">
									Send
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>;
}

export default App;
